export const HOME = '/',
    REGISTER = '/signup',
    REGISTER_POST = '/signup/post',
    LOGIN = '/login',
    FORGOT_PASSWORD = '/forgot-password',
    TERM_ADD = '/term/add',
    TERMS = '/term',
    TERM = '/term/:termId',
    TERM_SIDEBAR = '/sidebar-term/:termId',
    TEXT_CHECKER = '/text-checker',
    TEXT_CHECKER_RESULT = '/text-checker/result',
    TRANSLATION_ADD = '/term/:termId/translation/add',
    TRANSLATION_ADD_SIDEBAR = '/sidebar-term/:termId/translation/add',
    TRANSLATION = '/term/:termId/translation/:translationId',
    TRANSLATION_SIDEBAR = '/sidebar-term/:termId/translation/:translationId',
    TRANSLATION_REDIRECT = '/translation/:translationId',
    TRANSLATION_EXAMPLE = '/term/:termId/translation/:translationId/translation-example/:translationExampleId',
    TRANSLATION_EXAMPLE_SIDEBAR =
        '/sidebar-term/:termId/translation/:translationId/translation-example/:translationExampleId',
    TRANSLATION_EXAMPLE_REDIRECT = '/translation-example/:translationExampleId',
    TRANSLATION_EXAMPLE_ADD = '/term/:termId/translation/:translationId/translation-example/add',
    TRANSLATION_EXAMPLE_ADD_SIDEBAR = '/sidebar-term/:termId/translation/:translationId/translation-example/add',
    MANIFESTO = '/manifesto',
    USER = '/user/:userId',
    ADMIN = '/admin',
    ADMIN_COMMENTS = '/admin/comments',
    ADMIN_CONTENT = '/admin/content',
    ABOUT = '/about',
    CODE_OF_CONDUCT = '/code-of-conduct',
    NEWS = '/news',
    IMPRINT = '/imprint',
    EXTENSION = '/extension',
    PRIVACY = '/privacy';
